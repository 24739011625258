const showBundleCampaign = ref(null);

export const useBundleCampaign = () => {
    if (!showBundleCampaign.value) {
        showBundleCampaign.value = new Date() < new Date('2025-04-30');
    }

    return {
        showBundleCampaign,
    };
};
