import type {
    Product,
    ProductVariant,
} from '@b2b-platform/commerce/types/product';
import { parseInt } from 'lodash-es';

export const useCommerceTracking = () => {
    const { dataLayer } = useGtm();

    const trackAddToCart = (
        name: Product,
        productVariant: ProductVariant,
        quantity: number,
        totalPrice: number,
        addonId: string | null = null,
    ) => {
        dataLayer.push({
            event: 'add-to-cart',
            product: name,
            product_id: productVariant.id,
            products_total_value:
                (getPriceFromPriceTiers(productVariant.priceTiers, quantity)
                    ?.amount /
                    100) *
                quantity,
            quantity,
            value: productVariant.priceTiers?.[0].price?.amount / 100,
        });

        if (productVariant.includedAddons.data.length > 0) {
            const includedAddon = productVariant.includedAddons.data[0];
            dataLayer.push({
                event: 'add-to-cart',
                product: includedAddon.name,
                product_id: includedAddon.id,
                products_total_value:
                    (getPriceFromPriceTiers(includedAddon.priceTiers, quantity)
                        ?.amount *
                        quantity) /
                    100,
                quantity,
                value: includedAddon.priceTiers[0].price.amount / 100,
            });
        }

        if (addonId) {
            const addon = productVariant.availableAddons.data.find(
                (availableAddon) => availableAddon.id === addonId,
            );
            dataLayer.push({
                event: 'add-to-cart',
                product: addon.name,
                product_id: addon.id,
                products_total_value:
                    (getPriceFromPriceTiers(addon.priceTiers, quantity)
                        ?.amount *
                        quantity) /
                    100,
                quantity,
                value: addon.priceTiers[0].price.amount / 100,
            });
        }
    };

    const trackContactRequest = (name: string = '', method: string = '') => {
        dataLayer.push({
            event: 'product_request_success',
            method: method,
            products: name,
        });
    };
    return {
        trackAddToCart,
        trackContactRequest,
    };
};
