import {
    maxNumberValidationMessage,
    maxValidationMessage,
    minValidationMessage,
    requiredValidationMessage,
} from '@b2b-platform/commerce/utils/validation-messages';
import { array, boolean, literal, number, object, string, z } from 'zod';

export const COMPANY_LABEL = 'Externer Firmenwortlaut inkl. Rechtsform';
export const EMAIL_LABEL = 'E-Mail-Adresse';
export const FIRSTNAME_LABEL = 'Vorname';
export const LASTNAME_LABEL = 'Nachname';
export const NUMBER_OF_EMPLOYEES_LABEL = 'Anzahl der Mitarbeiter*innen';
export const PHONE_NUMBER_LABEL = 'Telefonnummer';

export const MAX_COMPANY_LENGTH = 250;
export const MAX_EMAIL_LENGTH = 100;
export const MAX_NAME_LENGTH = 50;
export const MAX_PHONE_LENGTH = 30;
export const MAX_NUMBER_OF_EMPLOYEES = 10000000;

export const contactRequestSchema = object({
    company: string()
        .min(1, {
            message: requiredValidationMessage(COMPANY_LABEL),
        })
        .max(MAX_COMPANY_LENGTH, {
            message: maxValidationMessage(COMPANY_LABEL, MAX_COMPANY_LENGTH),
        }),
    consent: boolean().refine((val) => val),
    email: string()
        .email({ message: `Keine gültige ${EMAIL_LABEL}.` })
        .max(MAX_EMAIL_LENGTH, {
            message: maxValidationMessage(EMAIL_LABEL, MAX_EMAIL_LENGTH),
        }),
    firstName: string()
        .min(1, {
            message: requiredValidationMessage(FIRSTNAME_LABEL),
        })
        .max(MAX_NAME_LENGTH, {
            message: maxValidationMessage(FIRSTNAME_LABEL, MAX_NAME_LENGTH),
        }),

    lastName: string()
        .min(1, {
            message: requiredValidationMessage(LASTNAME_LABEL),
        })
        .max(MAX_NAME_LENGTH, {
            message: maxValidationMessage(LASTNAME_LABEL, MAX_NAME_LENGTH),
        }),
    numberOfEmployees: number({
        invalid_type_error: requiredValidationMessage(
            NUMBER_OF_EMPLOYEES_LABEL,
        ),
        required_error: requiredValidationMessage(NUMBER_OF_EMPLOYEES_LABEL),
    }).max(MAX_NUMBER_OF_EMPLOYEES, {
        message: maxNumberValidationMessage(
            NUMBER_OF_EMPLOYEES_LABEL,
            new Intl.NumberFormat('de-DE').format(MAX_NUMBER_OF_EMPLOYEES),
        ),
    }),
    phoneNumber: string()
        .min(8, { message: minValidationMessage(PHONE_NUMBER_LABEL, 8) })
        .max(MAX_PHONE_LENGTH, {
            message: maxValidationMessage(PHONE_NUMBER_LABEL, MAX_PHONE_LENGTH),
        })
        .optional()
        .or(literal('')),
    products: array(string()),
    text: string().optional(),
});

export type ContactRequest = z.infer<typeof contactRequestSchema>;
