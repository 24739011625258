<script setup lang="ts">
import {
    COMPANY_LABEL,
    type ContactRequest,
    contactRequestSchema,
    EMAIL_LABEL,
    FIRSTNAME_LABEL,
    LASTNAME_LABEL,
    NUMBER_OF_EMPLOYEES_LABEL,
    PHONE_NUMBER_LABEL,
} from '@b2b-platform/commerce/schemas/contact-request';
import { useJamForm } from '@b2b-platform/manage-job-ads/composables/useJamForm';
import type { OptionItem } from '@b2b-platform/manage-job-ads/types/general';

const props = defineProps<{
    variant: 'light' | 'dark';
    trackingMethod: string;
    productTitle?: string;
}>();

const { trackContactRequest } = useCommerceTracking();
const runtimeConfig = useRuntimeConfig();
const karriereUrl = runtimeConfig.public.karriere.url;

const { user: userInfo } = useUser();
const user = userInfo.value?.user ?? null;

const {
    data: contactForm,
    hasError,
    errors,
    validate,
} = useJamForm<ContactRequest>({
    defaultData: {
        company: user?.company.fullName ?? '',
        consent: false,
        email: user?.email ?? '',
        firstName: user?.firstName ?? '',
        lastName: user?.lastName ?? '',
        numberOfEmployees:
            (user && parseInt(user.company.employees)) ?? undefined,
        phoneNumber: user?.company?.mainAddress?.phone ?? undefined,
        products: [],
    },
    validationRules: [contactRequestSchema],
});

const PRODUCTS: OptionItem[] = [
    'Stelleninserate',
    'Aktive Talentsuche',
    'Arbeitgeberprofil',
].map((product) => ({
    label: product,
    value: product,
}));

const isSubmitting = ref(false);
const hasSubmitted = ref(false);
const hasSubmitErrors = ref(false);

const submit = async () => {
    validate();
    if (!hasError.value) {
        isSubmitting.value = true;
        try {
            trackContactRequest(
                props.productTitle ??
                    (contactForm.value.products.length === 0
                        ? null
                        : contactForm.value.products.join(', ')),
                props.trackingMethod,
            );

            await $fetch(`/api/commerce/contact-request`, {
                body: { data: contactForm.value },
                method: 'post',
            });
            hasSubmitted.value = true;
        } catch (error) {
            hasSubmitErrors.value = true;
        }
        isSubmitting.value = false;
    }
};
</script>

<template>
    <div class="overflow-hidden">
        <Transition
            mode="out-in"
            enter-active-class="duration-500 ease-[cubic-bezier(0.77,0,0.18,1)]"
            enter-from-class="translate-x-full"
            enter-to-class="translate-x-0"
            leave-active-class="duration-500 ease-[cubic-bezier(0.77,0,0.18,1)]"
            leave-from-class="translate-x-0"
            leave-to-class="-translate-x-full"
        >
            <div
                v-if="isSubmitting"
                class="flex w-full flex-col items-center gap-4 py-7"
                :class="[variant === 'light' && 'text-white']"
            >
                <BaseLoadingDots />
                Ihre Anfrage wird übermittelt
            </div>
            <div
                v-else-if="hasSubmitErrors"
                class="flex flex-col gap-5"
                :class="[variant === 'light' && 'text-white']"
            >
                <JamBaseText> Leider ist ein Fehler aufgetreten. </JamBaseText>
                <JamBaseButton
                    variant="white-outline"
                    @click="hasSubmitErrors = false"
                >
                    Erneut versuchen
                </JamBaseButton>
            </div>

            <div
                v-else-if="hasSubmitted"
                class="flex flex-col items-center"
                :class="[variant === 'light' && 'text-white']"
            >
                <NuxtImg
                    src="/illustrations/check.webp"
                    alt="Grünes Häcken, symbolisiert eine erfolgreiche Anfrage."
                    loading="lazy"
                    decoding="auto"
                    width="96"
                    height="92"
                />
                <JamBaseText variant="large" class="mt-4">
                    Vielen Dank für Ihre Anfrage!
                </JamBaseText>
                <JamBaseText class="mt-1">
                    Wir melden uns in Kürze bei Ihnen.
                </JamBaseText>
                <JamBaseText>Ihr karriere.at Team</JamBaseText>
            </div>
            <form
                v-else
                class="grid grid-cols-1 gap-4 rounded-md text-left md:grid-cols-2 lg:max-w-[800px]"
                :class="[variant === 'light' && 'bg-white p-4 lg:p-6']"
            >
                <JamBaseText v-if="variant === 'light'" variant="large">
                    Kontaktanfrage
                </JamBaseText>
                <JamBaseInput
                    id="company"
                    v-model="contactForm.company"
                    :label="COMPANY_LABEL"
                    placeholder="Musterfirma GmbH"
                    :error="errors.company"
                    class="md:col-span-2"
                    @focusout="validate('company')"
                />
                <JamBaseInput
                    id="firstName"
                    v-model="contactForm.firstName"
                    :label="FIRSTNAME_LABEL"
                    placeholder="Alex"
                    :error="errors.firstName"
                    @focusout="validate('firstName')"
                />
                <JamBaseInput
                    id="lastName"
                    v-model="contactForm.lastName"
                    :label="LASTNAME_LABEL"
                    placeholder="Mustermensch"
                    :error="errors.lastName"
                    @focusout="validate('lastName')"
                />
                <JamBaseInput
                    id="email"
                    v-model="contactForm.email"
                    :label="EMAIL_LABEL"
                    type="email"
                    :error="errors.email"
                    placeholder="alex.muster@firma.at"
                    @focusout="validate('email')"
                />
                <JamBaseInput
                    id="phoneNumber"
                    v-model="contactForm.phoneNumber"
                    type="tel"
                    :label="`${PHONE_NUMBER_LABEL} - optional`"
                    :error="errors.phoneNumber"
                    placeholder="+43 664 123 456 78"
                    @focusout="validate('phoneNumber')"
                />
                <JamBaseInput
                    id="phoneNumber"
                    v-model="contactForm.numberOfEmployees"
                    :label="NUMBER_OF_EMPLOYEES_LABEL"
                    type="number"
                    min="0"
                    :error="errors.numberOfEmployees"
                    @focusout="validate('numberOfEmployees')"
                />
                <JamBaseMultiSelect
                    id="products"
                    v-model="contactForm.products"
                    label="Ich interessiere mich für - optional"
                    :options="PRODUCTS"
                    :error="errors.products"
                    @focusout="validate('products')"
                />
                <JamBaseTextArea
                    v-model="contactForm.text"
                    label="Ihre Anfrage - optional"
                    :limit="500"
                    resize
                    class="md:col-span-2"
                    :error="errors.text"
                />
                <JamBaseCheckbox
                    v-model="contactForm.consent"
                    label-style="break"
                    class="md:col-span-2"
                    :error="!!errors.consent"
                >
                    Mit Abschluss dieser Anfrage bzw. dieser Registrierung
                    erkläre ich, nicht als Verbraucher, sondern im Zusammenhang
                    mit einer Geschäftsbeziehung zwischen Unternehmen tätig zu
                    sein. Nähere Informationen zum Datenschutz finden Sie
                    <JamBaseLink
                        :href="`${karriereUrl}/datenschutzerklaerung#datenverarbeitung-und-unsere-produkte`"
                        target="_blank"
                        external
                    >
                        hier </JamBaseLink
                    >.
                </JamBaseCheckbox>

                <JamBaseButton
                    :variant="
                        variant === 'light' ? 'dark-gray-outline' : 'green'
                    "
                    :class="`w-full mt-4 ${variant === 'light' && 'md:w-fit'} ${
                        variant === 'dark' && 'md:col-span-2'
                    }`"
                    @click="submit"
                >
                    Anfrage abschicken
                </JamBaseButton>
            </form>
        </Transition>
    </div>
</template>
