export const requiredValidationMessage = (field: string) =>
    `${field} muss ausgefüllt werden.`;

export const minValidationMessage = (field: string, min: number) =>
    `${field} muss mindestens ${min} Zeichen lang sein.`;

export const maxValidationMessage = (field: string, max: number) =>
    `${field} darf maximal ${max} Zeichen lang sein.`;

export const maxNumberValidationMessage = (field: string, max: number) =>
    `${field} muss kleiner als ${max} sein.`;
